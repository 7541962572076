<template>
  <Modal
    :title="$t('overlays.sms-signature.title')"
    :subtitle="$t('overlays.sms-signature.subtitle')"
  >
    <template v-slot:footer>
      <button class="btn btn-primary btn-white" @click="reprint">
        {{ $t("overlays.sms-signature.buttons.reprint") }}
      </button>
      <button class="btn btn-primary btn-green" @click="confirm">
        {{ $t("overlays.sms-signature.buttons.confirm") }}
      </button>
      <button class="btn btn-primary btn-red" @click="reject">
        {{ $t("overlays.sms-signature.buttons.reject") }}
      </button>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import Modal from "@/components/Modal.vue";

export default {
  name: "OverlaySMSSignature",
  components: { Modal },

  methods: {
    ...mapActions("payment", [
      "printSignatureCheck",
      "confirmSMSPaymentSignature"
    ]),

    async reprint() {
      await this.printSignatureCheck();
    },

    confirm() {
      this.close(true);
    },

    reject() {
      this.close(false);
    },

    async close(matching = false) {
      await this.confirmSMSPaymentSignature(matching);

      this.$emit("hideOverlay", matching);
    }
  }
};
</script>
